.data-wrapper {
    /* wrap around a data area */
    padding: 5px;
    display: block;

    margin-bottom: 15px;
}

.data-wrapper h6 {
    /* the table title */
    font-weight: 700;
}

.data-wrapper .header-details {
    /* caption under tabke title. Ex: farm address */
    font-style: italic;
    font-size: 0.75rem;
    margin-bottom: 5px;
}

.data-table tr.MuiTableRow-head {
    position: relative;
    z-index: 100;
}

.data-table th {
    background-color: var(--sfBlueLight);
    border-width: 0 1px;
    border-style: solid;
    border-color: #efefef;
    color: var(--sfBlue);
}

.data-table th,
.data-table tr > td:first-child {
    /* header row and first column */
    /*font-weight: bold;*/
}

.data-record-deleted {
    text-decoration: line-through;
}

.data-table th {
    vertical-align: inherit;
}
