.navbar-divider {
  height: calc(100%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 300px;
  width: 1px;
}

.image-inline {
  width: 100%;
}