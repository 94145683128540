
/*.creator-content {*/
/*    max-width: 1200px;*/
/*}*/

.creator-content {
    position: fixed;
    height: calc(100vh - 140px);
    width: calc(100vw - 20px);
    margin-left: 10px;
    margin-right: 10px;
}


.svc-toolbox__category .svc-toolbox__tool .sv-action__content { /* verticl icon spacing */
    padding-top: calc(0.25 * var(--base-unit, 8px));
}


.svc-side-bar__container {
    padding-right: 0px;
}