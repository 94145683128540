.login-page-form-container {
  width: 480px;
  border: solid gray 1px;
  padding: 1em 3em 4em 3em;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .login-page-form-container {
    width: 100%;
    border: 0;
    margin-top: 0;
  }
}

.login-page-logo {
  background: white;
  border-radius: 5px;
  height: 65px;
  width: auto;
  margin: 1em;
}

.login-page-link {
  cursor: pointer;
}

.dialog-close-button {
  cursor: pointer;
  float: right;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  /*width: 20px;*/
}
