.app-bar-logo {
  background: white;
  border-radius: 5px;
  height: 40px;
}

.settings-icon,
.apps-icon {
  color: white;
  margin-right: 20px;
}

#settings-menu .settings-icon,
#apps-menu .apps-icon {
  color: var(--sfPrimary);
  vertical-align: text-bottom;
}
