.landing-page {
  margin: auto;
}

.landing-page-banner {
  padding: 2em;
  width: auto;
  text-align: center;
  align-content: center;
  font-variant: small-caps;
}

.landing-page-info {
  margin: 1em;
  padding: 1em;
}

.landing-page-link {
  text-decoration: none;
  color: darkblue;
}

.landing-page-card {
  height: 100%;
  /*min-width: 200px;*/
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.landing-page-card-content {
  height: 100%;
}

.landing-page-help-icon {
  float: right;
}

.green {
  color: green;
}

.gray {
  color: darkgray;
}

.landing-page-links {
  text-decoration: none;
}
