:root {
  --sfRed: #ff0000;
  --sfGreen: rgb(197, 223, 22);
  --sfGreenLight: #e8f2a2;
  --sfBlue: #078ca9;
  --sfBlueLight: #e6f3f6;
  --sfGray: #666666;
  --sfPrimary: var(--sfBlue);
  --sfSecondary: var(--sfBlueLight);
  --sfAccent: var(--sfGreen);
  --tableBgDark: #078ca9;
  --tableBgLight: #efefef; /* #EBFFFE */
  --sfSaveIcon: var(--sfGreen);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/*.app-bar-logo {*/
/*  cursor: pointer;*/
/*}*/

button.MuiButton-root {
  font-size: 0.675rem;
}

.MuiToolbar-root.tool-bar {
  min-height: 20px;
}

.clickable {
  cursor: pointer;
}
