/* settings navigation */

.setting-back-container h5,
.setting-back-container svg {
  display: inline;
  vertical-align: middle;
  font-size: 28px !important;
}

.MuiToggleButton-root.Mui-selected {
  background-color: var(--sfGreenLight) !important;
}

/* settings tables */
.setting-container thead th {
  font-weight: 700;
}

.setting-container thead th,
.setting-container tbody td {
  padding: 8px 8px;
}

.multi-column
  .MuiFormControl-root.MuiFormControl-marginDense.MuiFormControl-fullWidth.MuiTextField-root {
  margin-top: 0 !important;
}

/* setting type specific */
.setting-container.farm table {
  overflow-x: 'scroll';
}
