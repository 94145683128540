.edit-cell-title {
    background: #ebfcff;
    text-align: center;
    display: flex;
    flex-direction: row;
    place-content: center;
    place-items: center;
    color: #6f6f70;
    position: sticky;
    top: 0;
    z-index: 10;
}

.edit-cell-confirm {
    position: absolute;
    right: 4px;
}

.edit-cell-icon {
    opacity: 0.5;
    transition-duration: 700ms;
    cursor: pointer;
    vertical-align: middle;
}
.edit-cell-icon:hover {
    opacity: 1;
}

.edit-cell-icon-disabled {
    opacity: 0.2;
    transition-duration: 700ms;
    cursor: not-allowed;
}

.edit-cell-icon-hide {
    transition-duration: 700ms;
    position: absolute;
    right: 0;
    opacity: 0;
}
.edit-cell-icon-show {
    transition-duration: 700ms;
    position: absolute;
    right: 0;
    opacity: 1;
}

.edit-cell-driver-select {
    margin-top: 5px;
    background: rgba(1, 1, 1, 0);
    border: none;
    border-bottom: 1px solid gray;
}

.edit-cell-editable-field {
    position: relative;
    cursor: pointer;
}

.edit-cell-editable-field-empty {
    border-bottom: 1px dotted darkslategray;
    height: 60%;
    width: 10%;
    margin-left: 45%;
}

.edit-cell-edit-pencil {
    right: 2px;
    position: absolute;
    top: 2px;
    background: rgba(255, 255, 255, 0.7);
    padding: 2px;
    border: 1px solid gray;
    border-radius: 4px;
}

.edit-cell-edit-show {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    min-height: 25px;
}

.edit-cell-editable-field:hover .edit-cell-edit-show {
    opacity: 100;
}

.form-inline {
    display: inline;
}
.edit-cell-day-select {
    border: 0px;
    border-bottom: 1px solid;
    padding-bottom: 5px;
    margin-left: 0;
    transform: translateX(-5px);
    background: rgba(255, 255, 255, 0);
}

.table .edit-cell-add-new-schedule-cell {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}

.table-fixed-header {
    height: 400px;
    overflow-y: scroll;
}

.table-fixed-header thead th {
    position: sticky;
    top: 0;
    background: white;
    z-index: 100;
}

.edit-cell-hide-scrollbar {
    overflow-y: scroll;
    overflow-x: hidden;
}

.edit-cell-hide-scrollbar::-webkit-scrollbar {
    background-color: white;
    border-radius: 10px;
    width: 20px;
}

.edit-cell-hide-scrollbar::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 10px;
    border: 5px solid white;
}

.edit-cell-hide-scrollbar::-webkit-scrollbar-thumb:vertical {
    height: 20px !important;
    width: 20px;
}

.edit-cell-overlay {
    border-radius: 20px;
    position: absolute;
    height: calc(100% - 200px);
    width: 90%;
    background: rgba(0, 0, 0, 0.15);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.edit-cell .form-control input,
select.form-control {
    padding: 2px 2px;
    border-radius: 4px;
    border: 1px solid var(--sfPrimary);
    font-size: 0.875rem;
    color: var(--sfPrimary);
}

.edit-cell select.edit-cell {
    width: auto;
    max-width: calc(100% - 65px);
    min-width: 20px;
}

.edit-cell .number-control input[type='number'] {
    max-width: 80px;
}

/* center if cell text is centered */
.MuiTableCell-alignCenter .edit-cell .form-control input,
.MuiTableCell-alignCenter .edit-cell select.form-control {
    text-align: center;
}
