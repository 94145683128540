.float-right {
    float: right;
}

.float-left {
    float: left;
}

.red-text {
    color: indianred;
}

.survey-container {
    padding-left: 5%;
    padding-right: 5%;
}

.sv-container-modern__title {
    padding-top: 2em !important;
}