.backArrow {
    display: block;
    text-align: left;
}

.backArrow a {
    color: var(--tablePrimary);
}

.backArrow a:hover {
    filter: contrast(0.5);
}

.page-title {
    margin: 20px;
}